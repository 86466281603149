import { render, staticRenderFns } from "./BlokProseRelatedTemplate.vue?vue&type=template&id=05597488&"
import script from "./BlokProseRelatedTemplate.vue?vue&type=script&lang=js&"
export * from "./BlokProseRelatedTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SolidArrowRightIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/solid/src/components/ArrowRightIcon.vue').default,SolidPlayIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/solid/src/components/PlayIcon.vue').default,DTLoaderDots: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTLoaderDots/DTLoaderDots.vue').default})
