import { render, staticRenderFns } from "./default.vue?vue&type=template&id=06e869c5&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResourceSearch: require('/builds/docue/website/components/ResourceSearch.vue').default,VideoModal: require('/builds/docue/website/components/VideoModal.vue').default,AskForTemplateModal: require('/builds/docue/website/components/AskForTemplateModal.vue').default,CookieControl: require('/builds/docue/website/components/CookieControl.vue').default,ResponsiveIndicator: require('/builds/docue/website/components/ResponsiveIndicator.vue').default})
