export const state = () => ({
  searchOpen: false,
  searchQuery: '',
  searchTab: 'templates',
  openedVideo: null,
})

export const mutations = {
  SET_SEARCH_OPEN (state, value) {
    state.searchOpen = value
  },
  SET_SEARCH_TAB (state, tabKey) {
    state.searchTab = tabKey
  },
  SET_SEARCH_QUERY (state, query) {
    state.searchQuery = query
  },
  SET_SEARCH_OPEN_WITH_QUERY (state, query) {
    state.searchOpen = true
    state.searchQuery = query
  },
  SET_SEARCH_OPEN_WITH_TAB (state, tab) {
    state.searchOpen = true
    state.searchTab = tab
  },
  SET_OPENED_VIDEO (state, video) {
    state.openedVideo = video
  }
}
