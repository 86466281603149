export const makeRoot = (url) => {
  if (!url) { return url }
  if (url.startsWith('/')) { return url }
  if (url.startsWith('http')) { return url }

  return '/' + url
}

/**
 * Returns boolean value indicating if it is safe to redirect to that url.
 * Main reason is to check that ?return parameter cannot be used for malicious use.
 *
 * @param {string} url
 */
export const isSafe = (url) => {
  try {
    const u = new URL(url)
    const domain = u.hostname.split('.')
    const base = domain[domain.length - 2]
    const end = domain[domain.length - 1]

    return (base === 'sopti' && end === 'io') ||
      (base === 'eucod' && end === 'io') ||
      (base === 'sopimustieto' && end === 'fi') ||
      (base === 'docue' && end === 'com') ||
      (process.env.STAGE !== 'production' && (end === 'localhost' || end === 'test'))
  } catch (e) {
    return false
  }
}
