// These are in a separate file from constants.js because some stuff in constants.js is needed at build-time (modules/prebuild), and importing stuff that's only available at runtime (such as assets) won't work there.

import { LANGUAGE_FI, LANGUAGE_SV, LANGUAGE_EN, LANGUAGE_DE, LANGUAGE_PL, COUNTRY_FI, COUNTRY_SE, COUNTRY_GB, COUNTRY_DE, COUNTRY_PL } from './constants'

export const LANGUAGE_FLAG_IMGS = {
  [LANGUAGE_FI]: require('~/assets/images/flags/flag_fi.svg'),
  [LANGUAGE_SV]: require('~/assets/images/flags/flag_sv.svg'),
  [LANGUAGE_EN]: require('~/assets/images/flags/flag_gb.svg'),
  [LANGUAGE_DE]: require('~/assets/images/flags/flag_de.svg'),
  [LANGUAGE_PL]: require('~/assets/images/flags/flag_pl.svg'),
}

export const COUNTRY_FLAG_IMGS = {
  [COUNTRY_FI]: require('~/assets/images/flags/flag_fi.svg'),
  [COUNTRY_SE]: require('~/assets/images/flags/flag_sv.svg'),
  [COUNTRY_GB]: require('~/assets/images/flags/flag_gb.svg'),
  [COUNTRY_DE]: require('~/assets/images/flags/flag_de.svg'),
  [COUNTRY_PL]: require('~/assets/images/flags/flag_pl.svg'),
}

export const LEGISLATION_FLAG_IMGS = {
  FI: require('~/assets/images/flags/flag_fi.svg'),
  SE: require('~/assets/images/flags/flag_sv.svg'),
  DE: require('~/assets/images/flags/flag_de.svg'),
  'GB-EAW': require('~/assets/images/flags/flag_gb-eaw.svg'),
  'GB-SCT': require('~/assets/images/flags/flag_gb-sct.svg'),
  'GB-WLS': require('~/assets/images/flags/flag_gb-wls.svg'),
  'GB-NIR': require('~/assets/images/flags/flag_gb-nir.svg'),
  PL: require('~/assets/images/flags/flag_pl.svg'),
}
