//
//
//
//

import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SbAnchor',
  props: {
    attrs: {
      type: Object,
      required: true,
    },
  },
  setup ({ attrs }) {
    return {
      id: attrs.id,
    }
  },
})
