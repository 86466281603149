//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'SbRichTextLink',
  props: {
    attrs: {
      type: Object,
      required: true,
    },
  },
  setup ({ attrs }) {
    const linkType = ref(attrs.linktype)
    // CHANGED
    const href = ref(attrs.story ? `/${attrs.story.url}` : attrs.href)
    const target = ref(attrs.target)
    if (attrs.anchor) {
      href.value = `${href.value}#${attrs.anchor}`
    }

    return {
      linkType,
      href,
      target,
    }
  },
})
