//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'plain',

  // eslint-disable-next-line vue/require-prop-types
  props: ['error']
}
