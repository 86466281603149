import { LAYOUT_HEADER_STYLE_BEIGE, LAYOUT_HEADER_STYLE_WHITE, LAYOUT_HEADER_STYLE_BLACK, LAYOUT_HEADER_STYLE_LIGHTCREAM } from '~/utils/constants'

export default function (ctx, inject) {
  inject('ui', {
    LAYOUT_HEADER_STYLE_BEIGE,
    LAYOUT_HEADER_STYLE_WHITE,
    LAYOUT_HEADER_STYLE_BLACK,
    LAYOUT_HEADER_STYLE_LIGHTCREAM,
    headerContainer: 'tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8',
    container: 'tw-max-w-6xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8',

    v2: {
      container: 'tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8',
    }
  })
}
