//
//
//
//
//
//
//
//


export default {
  props: {
    htmlTag: {
      type: String,
      required: false,
      default: 'h2',
    },
  },
}

