export default function ({ app, store }) {
  app.$bugsnag.addOnError((event) => {
    const user = store.state.user
    const workspace = store.state.company

    if (user) {
      event.setUser(user.id, user.attributes.email)
      if (workspace) {
        event.addMetadata('company', {
          id: workspace.id,
          displayName: workspace.attributes.displayName,
        })
      }
    }

    const ingored = [
      'expected expression, got \'<\'',
      'Unexpected token \'<\'',
      'ERR_REDIRECT', // nuxt uses this internally when calling redirect(), this is NOT an actual error
    ]

    const message = event?.errors?.[0]?.errorMessage ?? ''

    const isIgnored = ingored.reduce((result, ignored) => {
      return message.includes(ignored) || result
    }, false)

    return !isIgnored
  })
}
