//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const excludedPages = [
  '/fi-fi/tietosuojaseloste',
  '/fi-fi/allekirjoittajan-tietosuojaseloste',
  '/fi-fi/kayttoehdot',
  '/sv-se/integritetspolicy',
  '/sv-se/villkor',
  '/en/terms',
  '/en/privacy'
]

export default {
  data () {
    return {
      // Defaults true to prevent flickering
      consentGiven: true,

      configure: false,
      ad: true,
      analytics: true,
      personalization: true,
      functionality: true,
      security: true,

      // Don't show cookie banner if on convert preview
      disable: this.$route.query.disable_cookie_banner === 'true' || this.$route.query.visualEditor === 'convert'
    }
  },
  computed: {
    isStoryblok () {
      return !!this.$route.query._storyblok
    },
    visible () {
      return (!this.consentGiven && !this.isStoryblok && !this.disable && !this.isExcludedPage) || this.cookieControlOpen
    },
    isExcludedPage () {
      return excludedPages.includes(this.$route.path)
    },
    cookieControlOpen () {
      return this.$store.state.cookieControlOpen
    }
  },
  watch: {
    visible (visible) {
      if (visible) {
        document.body.classList.add('prevent-scroll')
      } else {
        document.body.classList.remove('prevent-scroll')
      }
    },
    cookieControlOpen (o) {
      // When dialog opened again, lets
      // open the configure view immediately
      if (o) {
        this.configure = true
      }
    }
  },
  mounted () {
    this.updateConsentGiven()
    const cookies = this.$cookies.get('CookieConsent')
    if (cookies) {
      this.ad = cookies.ad ?? this.ad
      this.analytics = cookies.analytics ?? this.analytics
      this.personalization = cookies.personalization ?? this.personalization
    }
  },
  methods: {
    openConfigure () {
      this.configure = true

      // Make optional cookies disabled by default
      this.ad = false
      this.analytics = false
      this.personalization = false
    },
    accept () {
      this.$store.commit('SET_COOKIE_CONTROL_OPEN', false)

      this.setCookie({
        ad: this.ad,
        analytics: this.analytics,
        personalization: this.personalization,
        functionality: true,
        security: true
      })
      this.$gtm.push({
        event: 'update_cookie_consent'
      })
      if (this.analytics) {
        this.$ph.enable()
      }
    },
    setCookie (data) {
      const options = {
        maxAge: 60 * 60 * 24 * 365,
        secure: this.$config.cookie.secure,
        path: '/',
      }

      const domain = this.$config.cookie.domain
      if (domain) {
        options.domain = domain
      }

      this.$cookies.set('CookieConsent', data, options)
      this.updateConsentGiven()
    },
    updateConsentGiven () {
      this.consentGiven = !!this.$cookies.get('CookieConsent')
    }
  }
}
