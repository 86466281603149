export const state = () => ({
  cookieControlOpen: false,
  user: null,
  workspace: null,
})

export const mutations = {
  SET_COOKIE_CONTROL_OPEN (state, value) {
    state.cookieControlOpen = value
  },
  SET_USER (state, user) {
    state.user = user
  },
  SET_WORKSPACE (state, workspace) {
    state.workspace = workspace
  },
}
