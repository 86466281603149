export default function ({ app }, inject) {
  inject('meili', {
    async search (type, params) {
      let indexName = type
      if (type === 'legal_hub') {
        indexName = app.$config.meiliLegalHubIndexName
      }

      if (type === 'legal_hub') {
        const localeFilter = `locale = '${app.$country.current.code}'`
        if (params.filter) {
          params.filter = `${localeFilter} AND ${params.filter}`
        } else {
          params.filter = localeFilter
        }
      }

      const res = await fetch(`${app.$config.meiliApiUrl}/indexes/${indexName}/search`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${app.$config.meiliApiSearchKey}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(params),
      })
      if (res.status < 200 || res.status > 299) {
        let errInfo = ''
        try {
          errInfo = await res.text()
        } catch (e) {
          // Ignore error here
        }
        throw new Error(`Meilisearch request failed (${res.status})\n${errInfo}`)
      }
      const json = await res.json()
      return json
    },
  })
}
