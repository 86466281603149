export default function ({ $country }, inject) {
  const languageNames = new Intl.DisplayNames($country.current.language, { type: 'language' })

  const intl = {
    language: {
      displayName (language) {
        return languageNames.of(language)
      }
    }
  }

  inject('intl', intl)
}
