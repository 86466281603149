import { render, staticRenderFns } from "./BlokProseBanner.vue?vue&type=template&id=8868f382&"
import script from "./BlokProseBanner.vue?vue&type=script&lang=js&"
export * from "./BlokProseBanner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomLinkV2: require('/builds/docue/website/components/CustomLinkV2.vue').default,DTPanel: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTPanel/DTPanel.vue').default})
