import Vue from 'vue'
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
import { Mark } from '@marvr/storyblok-rich-text-types'
import SbRichTextLink from '@/components/SbRichTextLink.vue'
import SbAnchor from '@/components/SbAnchor.vue'
import ProseBanner from '@/components/Blok/BlokProseBanner.vue'
import ProseTable from '@/components/Blok/BlokProseTable.vue'
import ProseImgLink from '@/components/Blok/BlokProseImgLink.vue'
import ProseTitle from '@/components/Blok/BlokProseTitle.vue'
import ProseButton from '@/components/Blok/BlokProseButton.vue'
import ProseRelatedTemplate from '@/components/Blok/BlokProseRelatedTemplate.vue'

Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
      ProseBanner,
      ProseTable,
      ProseImgLink,
      ProseTitle,
      ProseRelatedTemplate,
      ProseButton,
    },
    marks: {
      // See the comments in the component file
      [Mark.LINK]: SbRichTextLink,
      anchor: SbAnchor,
    },
  },
})
