//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { LANGUAGE_FLAG_IMGS, LEGISLATION_FLAG_IMGS } from '~/utils/runtimeConstants'

// NOTE: also see Templates/LandingPage!

const parseYoutubeDuration = (duration) => {
  const hours = duration.match(/(\d+)H/)
  const minutes = duration.match(/(\d+)M/)
  const seconds = duration.match(/(\d+)S/)

  return {
    minutes: parseInt(minutes[1]) + (hours ? parseInt(hours[1]) * 60 : 0),
    seconds: seconds ? parseInt(seconds[1]) : 0
  }
}

export default {
  props: {
    body: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      template: null,
      isLoading: true,
    }
  },

  async fetch () {
    // We allow the ID field to also include the article slug or even a full URL, as long as the last part is the actual template ID.
    const concreteTemplateId = this.body.template_id.split('/').pop()
    let templateRes = null
    try {
      templateRes = await this.$api.getTemplate(concreteTemplateId, null, { include: 'categories' })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)

      this.isLoading = false

      return
    }

    this.template = templateRes.data.data.attributes
    this.isLoading = false

    this.template.legislations = this.template.jurisdictions.map(j => ({ ...j, flagSrc: LEGISLATION_FLAG_IMGS[j.code] }))

    this.template.languageFlagSrc = LANGUAGE_FLAG_IMGS[this.template.language]

    this.template.categories = (templateRes.data.data.relationships?.categories?.data || []).map((c) => {
      const category = templateRes.data.included.find(i => i.id === c.id && i.type === c.type)
      return {
        id: category.id,
        name: category.attributes.name,
        url: this.$url.templateCategory(category.id, category.attributes.slug)
      }
    })

    // NOTE: Also see Templates/TopSection!
    // Fetch youtube video data
    if (this.template.videoIds && this.template.videoIds.length) {
      try {
        const url = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${this.template.videoIds[0]}&key=${this.$config.youtubeApiKey}`
        this.template.videos = await this.$axios.get(url).then(r => r.data.items.map(v => ({
          id: v.id,
          title: v.snippet.title,
          thumbnail: v.snippet.thumbnails.high,
          duration: parseYoutubeDuration(v.contentDetails.duration)
        })))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch youtube information for template videos: ' + this.template.id)
      }
    }
  },

  computed: {
    templateUrl () {
      if (!this.template) {
        return ''
      }
      return this.$url.template(this.body.template_id, this.template.slug)
    },

    categoriesStr () {
      if (!this.template) {
        return ''
      }
      return this.template.categories.map(x => x.name).join(', ')
    },
  },
}

