//
//
//
//
//
//
//
//
//
//
//
//
//
//

const SIZE_SMALL = 'sm'
const SIZE_MEDIUM = 'md'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      default: SIZE_MEDIUM,
      validator: size => [SIZE_SMALL, SIZE_MEDIUM].includes(size)
    },
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invert: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dotClass () {
      const cls = []

      if (this.size === SIZE_SMALL) {
        cls.push('tw-w-3 tw-h-3')
        if (this.value) {
          cls.push('tw-translate-x-2')
        }
      } else {
        cls.push('tw-w-5 tw-h-5')
        if (this.value) {
          cls.push('tw-translate-x-5')
        }
      }

      if (!this.value) {
        cls.push('tw-translate-x-0')
      }

      return cls
    },
    toggleClass () {
      const cls = [
        'tw-relative',
        'tw-inline-flex',
        'tw-flesh-shrink-0',
        'tw-border-2',
        'tw-border-transparent',
        'tw-rounded-full',
        'tw-transition-colors',
        'tw-ease-in-out',
        'tw-duration-200',
        'focus:tw-outline-none',
        'focus:tw-ring-2',
        'focus:tw-ring-offset-2',
        'focus:tw-ring-red-700'
      ]

      if (this.value) {
        cls.push('tw-bg-red-600')
      } else {
        cls.push('tw-bg-gray-200')
      }

      if (this.disabled) {
        cls.push('tw-bg-opacity-50 tw-cursor-default')
      }

      if (this.size === SIZE_SMALL) {
        cls.push('tw-h-4 tw-w-6')
      } else {
        cls.push('tw-h-6 tw-w-11')
      }

      return cls
    }
  },
  methods: {
    toggle () {
      if (this.disabled) {
        return
      }

      this.$emit('input', !this.value)
    }
  }
}
