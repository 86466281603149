//
//
//
//
//
//

import { makeRoot, isSafe } from '~/utils/url'

export default {
  props: {
    url: {
      type: String,
      required: true,
      default: null
    },
  },
  computed: {
    localUrl () {
      return makeRoot(this.url)
    },
    isExternal () {
      return this.localUrl ? this.localUrl[0] !== '/' : false
    },
    urlProps () {
      if (this.isExternal || this.localUrl.includes('#')) {
        return {
          href: this.localUrl,
          rel: this.urlRel
        }
      }
      return {
        to: this.localUrl
      }
    },
    urlRel () {
      return isSafe(this.localUrl) || !this.isExternal ? null : 'noopener noreferrer'
    },
    boundAttributes () {
      return {
        ...this.$attrs,
        ...this.urlProps
      }
    }
  },
}
