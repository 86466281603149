//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    openedVideo: {
      get () {
        return this.$store.state.layout.openedVideo
      },
      set (value) {
        this.$store.commit('layout/SET_OPENED_VIDEO', value)
      }
    },
    videoUrl () {
      if (!this.openedVideo) {
        return null
      }

      return `https://www.youtube-nocookie.com/embed/${this.openedVideo}?rel=0&modestbranding=1&autoplay=1`
    }
  },
  watch: {
    openedVideo (video) {
      if (video) {
        document.body.classList.add('prevent-scroll')
      } else {
        document.body.classList.remove('prevent-scroll')
      }
    }
  },
  methods: {
    hide () {
      this.openedVideo = null
    }
  }
}
