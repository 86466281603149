export default ({ app }, inject) => {
  const appId = app.$config.intercom.appId

  if (!window.Intercom) {
    const Intercom = function () {
      Intercom.c(arguments)
    }

    Intercom.q = []
    Intercom.c = function (args) {
      Intercom.q.push(args)
    }

    window.Intercom = Intercom

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://widget.intercom.io/widget/' + appId
    document.body.appendChild(script)
  }

  inject('intercom', {
    boot (options = {}) {
      if (!window.Intercom) {
        return
      }

      window.Intercom('boot', {
        app_id: appId,
        ...options
      })
    },
    call () {
      if (!window.Intercom) {
        return
      }

      window.Intercom(...arguments)
    }
  })
}
