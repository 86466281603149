//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: null,
      email: null,
      isLoading: false,
      hasError: false,
      hasRequestBeenSent: false
    }
  },
  computed: {
    isDirty () {
      return this.message || this.email
    }
  },
  methods: {
    async submit () {
      if (!(this.email || this.message) || this.isLoading) { return }

      this.isLoading = true
      this.hasError = false

      try {
        await this.$api.v2.templateRequest({
          email: this.email,
          message: this.message,
          country: this.$country.current.countryCode,
        })

        this.hasRequestBeenSent = true
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        this.hasError = true
      } finally {
        this.isLoading = false
      }
    },
    hide (checkDirty = false) {
      if (this.isLoading || (checkDirty && this.isDirty)) {
        return
      }

      this.$emit('input', false)
    }
  }
}
