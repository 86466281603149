import { posthog } from 'posthog-js'

export default function ({ app, store, $config }, inject) {
  if ($config.posthog.token) {
    posthog.init($config.posthog.token, {
      api_host: 'https://eu.posthog.com',
      disable_session_recording: true,
      debug: $config.stage === 'development',
      persistence: app.$cookies.get('CookieConsent')?.analytics ? 'cookie' : 'memory',
      session_recording: {
        maskTextSelector: '*'
      }
    })
  }

  posthog.enable = function () {
    posthog.set_config({
      persistence: 'cookie',
    })
  }

  inject('ph', posthog)
}
