//
//
//
//
//

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: null
    }
  },
  computed: {
    isExternal () {
      return this.url ? this.url[0] !== '/' : false
    },
    rel () {
      return this.isExternal ? 'noopener noreferrer' : ''
    },
    component () {
      // Internal urls with # needs a tag
      // to make them work correctly
      if (this.url?.includes('#')) {
        return 'a'
      }

      return this.isExternal ? 'a' : 'nuxt-link'
    }
  }
}
