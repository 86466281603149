export default function ({ $api, $gtm, $ph, store, $cookies, $config }) {
  const loadUserInformation = async () => {
    const token = $cookies.get('api_token')
    const account = $cookies.get('active_account')
    if (token && account) {
      try {
        const user = await $api.getProfile().then(r => r.data.data)
        $gtm.push({
          event: 'user_provided',
          user_id: user.id,
          user_email: user.attributes.email
        })

        $ph.identify(user.id, {
          email: user.attributes.email
        })

        store.commit('SET_USER', user)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)

        if (e.response?.status === 401) {
          // Clear cookies if authentication
          // fails to prevent error flooding
          $cookies.remove('api_token', { domain: $config.cookie.domain, path: '/' })
          $cookies.remove('active_account', { domain: $config.cookie.domain, path: '/' })
        }
      }

      try {
        const workspace = await $api.getWorkspace().then(r => r.data.data)
        $gtm.push({
          event: 'workspace_provided',
          workspace_id: workspace.id,
          workspace_type: workspace.attributes.workspaceType,
          workspace_has_active_subscription: workspace.attributes.subscription_status === 'active',
          workspace_country: workspace.attributes.country,
        })

        $ph.group('workspace', workspace.id, {
          name: workspace.attributes.displayName,
          created_at: workspace.attributes.createdAt,
          country: workspace.attributes.country,
          type: workspace.attributes.workspaceType,
          subscription_status: workspace.attributes.subscription_status,
          subscription_plan: workspace.attributes.subscription_plan
        })

        store.commit('SET_WORKSPACE', workspace)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
      }
    }
  }

  loadUserInformation()
}
