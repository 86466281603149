import { COUNTRIES } from '~/utils/constants'

export default function ({ route, app, error }) {
  if (route.meta[0]?.ignoreCountry) {
    return
  }

  // Try first by fetching the
  // country via the parameter
  let country = COUNTRIES[route.params.country]

  // If parameter does not exists (eq. hardoded page /fi-fi/asiakirjamallit)
  // try by matching the path
  if (!country) {
    country = Object.keys(COUNTRIES).map(key => COUNTRIES[key]).find(c => route.path.startsWith('/' + c.code + '/'))
  }

  // If still does not eixsts it has to be invalid
  // --> show error
  if (!country) {
    error({
      statusCode: 404,
      message: 'Page not found'
    })
    return
  }

  app.$country.setCurrent(country)
}
