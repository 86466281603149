import { render, staticRenderFns } from "./AskForTemplateModal.vue?vue&type=template&id=def72f60&scoped=true&"
import script from "./AskForTemplateModal.vue?vue&type=script&lang=js&"
export * from "./AskForTemplateModal.vue?vue&type=script&lang=js&"
import style0 from "./AskForTemplateModal.vue?vue&type=style&index=0&id=def72f60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "def72f60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OutlineXIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/outline/src/components/XIcon.vue').default,SolidCheckCircleIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/solid/src/components/CheckCircleIcon.vue').default,DTButton: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTButton/DTButton.vue').default,DTSpinner: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTSpinner/DTSpinner.vue').default})
