//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    body: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image () {
      return this.$sb.parseImage(this.body?.image || {})
    },
    filename () {
      return this.image?.filename || ''
    },
    alt () {
      return this.image?.alt || ''
    }
  },
}
