//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data () {
    return {
      showAskForTemplate: false
    }
  },
  head () {
    return {
      htmlAttrs: {
        lang: this.$country.current.language
      },
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://${this.$config.siteUrl}${this.$route.path}`
        }
      ]
    }
  },
  watch: {
    '$route.fullPath' (fullPath) {
      this.$intercom.call('update')
      this.$ph.capture('$pageview')
    },
  },
  mounted () {
    this.$intercom.boot()
  }
}
