import { last } from '~/utils/array'
import { isFilledRichText, parseLink } from '~/utils/storyblok'

export default function ({ route, isDev, app, enablePreview }, inject) {
  if (process.client && (route.query._storyblok || route.query._preview)) {
    enablePreview()
  }

  const parseAsset = (asset) => {
    if (!asset || !asset.filename) {
      return null
    }

    const data = { ...asset }
    const filename = asset.filename.split('/')

    // If the url starts with // we need to add https
    if (data.filename.startsWith('//')) {
      data.filename = 'https:' + data.filename
    }

    data.extension = last(last(filename).split('.'))

    if (/jpg|jpeg|png|svg|webp/.test(data.extension)) {
      data.type = 'image'
    } else if (/mp4|webm/.test(data.extension)) {
      data.type = 'video'
    }

    data.alt = data.alt || ''

    return data
  }

  inject('sb', {
    parseAsset,
    isFilledRichText,
    parseLink,
    parseImage (image) {
      const data = parseAsset(image)
      if (!data) { return data }

      const filename = data.filename.split('/')
      const size = filename[filename.length - 3]?.split('x')

      data.width = size[0] ? parseInt(size[0]) : null
      data.height = size[1] ? parseInt(size[1]) : null

      // Storyblok image service will convert svgs to pngs so we will
      // use dummy provider for those
      data.provider = data.extension === 'svg' ? 'dummy' : 'storyblok'

      return data
    },
    async get (slug, params = {}) {
      const version = (route.query._storyblok || isDev || app.$config.storyblokUseDraft) ? 'draft' : 'published'
      const query = Object.assign({ version }, params)
      if (version === 'published') {
        query.cv = app.$config.storyblokCacheVersion
      } else {
        query.cv = Date.now()
      }

      return await app.$storyapi.get(slug, query)
    },
    async list (startsWith, params = {}) {
      const version = (route.query._storyblok || isDev || app.$config.storyblokUseDraft) ? 'draft' : 'published'
      const query = Object.assign({
        starts_with: startsWith,
        version
      }, params)

      if (version === 'published') {
        query.cv = app.$config.storyblokCacheVersion
      } else {
        query.cv = Date.now()
      }

      return await app.$storyapi.get('cdn/stories', query)
    },
  })
}
