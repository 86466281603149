/**
 * Method for filtering distinct elements of array with getter method.
 * Usage:
 *  [{id: 1}, {id: 1}, {id: 2}].filter(distinctFilter((e) => e.id))
 *
 * @param {Function} getter
 */
export const distinctFilterBy = getter => (value, index, self) => {
  const currentValue = getter(value)

  // return true if no same value found from self
  const hasDuplicate = self.find((findValue, findIndex) => getter(findValue) === currentValue && findIndex !== index && findIndex > index)

  return !hasDuplicate
}

export const last = arr => arr[arr.length - 1]
