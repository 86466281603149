import { makeRoot } from './url'

export const getBlokComponentName = (blok) => {
  return 'blok-' + blok
}

export const paginateAll = async (axios, path, options = {}) => {
  let stories = []
  let page = 1
  let total = 0
  do {
    if (!options.params) {
      options.params = {}
    }

    options.params.per_page = 100
    options.params.page = page

    if (!options.timeout) {
      options.timeout = 30000
    }

    const response = await axios.get(path, options)

    total = response.headers.total
    stories = stories.concat(response.data.stories)
    page++
  } while (stories.length < total)

  return stories
}

// Mutate the blok data to add a reference to the story and related stories as well, since some of these can be useful for page rendering. This is a convenient way to pass them around, since our components all work with blok-level objects. Note that this creates a circular structure on the story object.
export const storyDataToBlok = (storyData) => {
  const blok = storyData.story.content
  blok.story = storyData.story
  blok.rels = storyData.rels
  return blok
}

export const parseLink = (link) => {
  if (!link) {
    return ''
  }

  let url = null
  // Story-typed links should contain this when `resolve_links` is used. It's the most up-to-date info on story links.
  if (link.story && link.story.url) {
    url = link.story.url
  // Non-story link
  } else if (link.url) {
    url = link.url
  // Fallback - should be always present on both story and non-story links. This may not be as up-to-date as the other alternatives, but usually works just right.
  } else {
    url = link.cached_url
  }

  if (!url) {
    return ''
  }

  const anchor = link.anchor ? `#${link.anchor}` : ''
  return `${makeRoot(url)}${anchor}`
}

// Use this to figure out if a rich text field's value is set. Just a simple truthiness check won't work if the user has filled the field and then later emptied it.
export const isFilledRichText = (value) => {
  return value && value.content && value.content.length > 0 && (value.content[0].type !== 'paragraph' || value.content[0].content)
}
